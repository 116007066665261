/* src/components/Button/Button.css */
.button {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and label */
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    justify-content: center;
  }
  
  .button.primary {
    background-color: #09375F;
    color: white;
  }
  
  .button.secondary {
    background-color: #FFFFFF;
    color: #09375F;
    border: 1px solid #09375F;
  }


  