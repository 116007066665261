/* src/components/Dropdown/Dropdown.css */
.dropdown-container {
    position: relative;
}

.dropdown-menu-custom {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    color: #09375F;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
    color: #09375F;
}

#dropdown-basic{
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and label */
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    justify-content: center;
    background-color: #FFFFFF;
    color: #09375F;
    border: 1px solid #09375F;
    
}