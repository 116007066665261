.modal-dapster {
  border-radius: 20px;
}
.modal-dock {
  font-weight: bold;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .modal-dock-value {
    background-color: rgb(14, 104, 208);
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 14px;
    color: white;
  }
}
.modal-form-group {
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 14px;
    color: #4a5568;
    text-align: left;
    margin-bottom: 8px;
  }

  .select-wrapper {
    position: relative;

    select {
      width: 100%;
      padding: 12px;
      padding-right: 40px;
      background-color: white;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      appearance: none;
      font-size: 14px;
      cursor: pointer;
      transition: border-color 0.2s ease;

      &:focus {
        outline: none;
        border-color: #3182ce;
        box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
      }

      &:hover {
        border-color: #cbd5e0;
      }
    }

    .select-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #a0aec0;
      pointer-events: none;
    }
  }
}

.rationale-button {
  &:disabled {
    opacity: 0.4;
  }
}
