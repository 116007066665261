.tabs {
  width: 100%;
  .tabs-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
    padding: 12px;
    height: 89px;
    box-sizing: border-box;
    width: 100%;
  }
  .tab {
    color: #666666;
    font-size: 1.4rem;
    padding: 15px 40px;
    flex: 1;
    border-bottom: 4px solid #d0e7f6;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    white-space: nowrap;
    &.selected-tab {
      color: #09375f;
      border-bottom: 4px solid #09375f;
      font-weight: 700;
    }
    span {
      font-size: 1.2rem;
    }
  }
  .tabs-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
  }
}
