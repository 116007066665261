@mixin dapster-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
}

@mixin dapster-button {
  padding: 12px 16px;
  background-color: #1a365d;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2c5282;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(26, 54, 93, 0.3);
  }
}
