


.align-buttons{
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
}


