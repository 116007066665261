// Login.scss
$primary-color: #e2f1fc;
$secondary-color: #09375f;

$text-color: #333;

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  background-image: url("../../assets/images/dapster-bg.svg");
  background-size: cover;
  background-repeat: none;
  background-position: center;
  .login-app-name{
    font-size: 60px;
    font-weight: 700;
    color:white;
    margin:0px auto 30px;
  }
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 46px 0px #09375f26;
  color: $secondary-color;
  min-width: 400px;
}

.login-title {
  font-size: 1.5rem;
  margin-bottom: 4rem;
  font-weight: 700;
}

.login-form {
  width: 100%;
  height: auto;
}

.login-label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: $text-color;
}

.login-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e1f1fb;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}

.password-input-container {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 35%;
  right: 1rem;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  svg{
    path{
      fill:$secondary-color
    }
  }
}

.password-toggle-icon {
  // Add your password toggle icon styles here
}

.forgot-password {
  display: block;
  text-align: right;
  color: $secondary-color;
  text-decoration: none;
  margin-bottom: 1.3rem;
}

.login-btn {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: $secondary-color;
  color: $primary-color;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  margin-bottom: 0px;
  cursor: pointer;
}

.invalid-message {
  margin: 20px auto;
  text-align: center;
  color: red;

}
