@import "../../assets/scss/mixins.scss";

.custom-table {
  @include dapster-cards();
  height: 100px;
  width: 100px;
}
// AlertsTable.scss

// Variables
$color-primary: #1a73e8;
$color-border: #e0e0e0;
$color-background: #f8f9fa;
$color-text: #333333;
$color-text-light: #666666;
$color-success: #34a853;
$color-white: #ffffff;
$border-radius: 8px;
$transition: all 0.2s ease-in-out;

.alerts {
  padding: 24px;
  width: 100%;

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: $color-text;
    margin-bottom: 24px;
  }

  &__container {
    background-color: $color-white;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 16px;
      text-align: left;
      border-bottom: 1px solid $color-border;
    }
    tr {
      border-bottom: 1px solid $color-border;
    }
  }

  &__header {
    background-color: $color-background;

    th {
      font-weight: 500;
      color: $color-text;
      font-size: 14px;
      text-transform: none;
      padding: 16px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  &__body {
    tr {
      transition: $transition;

      &:hover {
        background-color: $color-background;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td {
      color: $color-text;
      font-size: 14px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  &__select-wrapper {
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $color-text-light;
      pointer-events: none;
    }
  }

  &__select {
    width: 100%;
    padding: 8px 32px 8px 12px;
    appearance: none;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-radius: 4px;
    font-size: 14px;
    color: $color-text;
    cursor: pointer;
    transition: $transition;

    &:focus {
      outline: none;
      border-color: $color-primary;
      box-shadow: 0 0 0 2px rgba($color-primary, 0.1);
    }

    &:hover {
      border-color: darken($color-border, 10%);
    }
  }

  &__check {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      color: $color-success;
    }
  }
}

.table-action-btn {
  background-color: none;
  border: none;
  background: none;
  font-size: 32px;
  padding: 5px;
  &:hover {
    svg path {
      fill: #027948;
    }
  }
  svg path {
    fill: #53d09c;
    &:hover {
      fill: #027948;
    }
  }
}

.table-job-actions-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .table-action-btn {
    font-size: 32px;
    padding: 5px;
    &.play {
      &:hover {
        svg path {
          fill: #027948;
        }
      }
      svg path {
        fill: #53d09c;
        &:hover {
          fill: #027948;
        }
      }
    }
    &.pause {
      &:hover {
        svg path {
          fill: #024679;
        }
      }
      svg path {
        fill: #538dd0;
        &:hover {
          fill: #024679;
        }
      }
    }
    &.cancel {
      &:hover {
        svg path {
          fill: #790202;
        }
      }
      svg path {
        fill: #d05353;
      }
    }
    &.rationales {
      @include dapster-button;
      font-size: 12px;
      white-space: nowrap;
      padding: 6px 10px;
      border: none;
      outline: none;
      margin-left: 5px;
    }
    &.disabled {
      cursor: auto;
      opacity: 0.4;
    }
  }
}

.table-alert-wrapper{
  width: 100% !important;
  margin: 30px;
}

// Responsive styles
@media screen and (max-width: 768px) {
  .alerts {
    padding: 16px;

    &__table {
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    &__title {
      font-size: 18px;
    }

    td,
    th {
      padding: 12px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}
