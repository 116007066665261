@import "../../assets/scss/mixins.scss";
@import "../../assets/scss/variable.scss";

.start-wrapper {
  width: 100%;
  h1 {
    font-size: 1.4rem;
    margin-bottom: 30px;
    text-align: left;
  }
  .start-flex-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
    .start-card {
      @include dapster-cards();
      padding: 20px;
      flex-direction: row;
      justify-content: flex-start !important;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      img {
        width: 65px;
        height: 65px;
      }
      .start-card-heading {
        flex: 1;
        text-align: left;

        h2 {
          font-weight: 600;
          font-weight: 0.8rem;
          margin-top: 0px;
          margin-bottom: 10px;
        }
        p {
          font-weight: 0.8rem;
          margin: 0px;
          padding: 0px;
        }
      }
      .start-arrow-circle {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: $primary;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
          margin-bottom: 7px;
        }
      }
    }
  }
}

// DapsterForm.scss
.dapster-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  @include dapster-cards();
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  .form-group {
    margin-bottom: 24px;

    label {
      display: block;
      font-size: 14px;
      color: #4a5568;
      text-align: left;
      margin-bottom: 8px;
    }

    .select-wrapper {
      position: relative;

      select {
        width: 100%;
        padding: 12px;
        padding-right: 40px;
        background-color: white;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        appearance: none;
        font-size: 14px;
        cursor: pointer;
        transition: border-color 0.2s ease;

        &:focus {
          outline: none;
          border-color: #3182ce;
          box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
        }

        &:hover {
          border-color: #cbd5e0;
        }
      }

      .select-icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: #a0aec0;
        pointer-events: none;
      }
    }
  }

  .submit-button {
    width: 100%;
    padding: 12px 16px;
    background-color: #1a365d;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #2c5282;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(26, 54, 93, 0.3);
    }
  }
}
