.layout {
  width: 100%;
  height: 100vh;
  background-color: #e2f1fc;
  box-sizing: border-box;

  .layout-header {
    background: #09375f;
    width: 100%;
    padding: 60px 0px 40px 0px;
    border-radius: 0px 0px 40px 40px;
    font-size: 5rem;
    font-weight: 700;
    color: white;
    text-align: center;
  }
  .layout-content {
    padding: 50px;
    width: 100%;
    height: 100%;
    background-color: #e2f1fc;
  }
  .layout-tabs-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border: 1px solid #09375f;
    padding: 8px;
    height: 89px;
    border-radius: 18px;
    box-sizing: border-box;
    width: 100%;
    gap: 10px;
  }

  .layout-tab {
    background-color: white;
    color: #09375f;
    font-size: 1.8rem;
    padding: 15px 40px;
    border-radius: 15px;
    font-weight: 600;
    flex: 1;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    &.selected-tab {
      background-color: #09375f;
      color: white;
    }
  }
  .layout-children {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
  }
}

button {
  background-color: #09375f;
  color: white;
  border-radius: 15px;
  padding: 10px 20px;
  border: none;
  display: block;
  font-size: 1.1rem;
  cursor: pointer;
}
