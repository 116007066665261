@import "../../assets/scss/mixins.scss";
@import "../../assets/scss/variable.scss";


h1 {
    font-size: 1.4rem;
    margin-bottom: 30px;
    text-align: left;
}


.start-card {
    @include dapster-cards();
    padding: 20px;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: 0.2s;
    width: 100%;
    margin: 5px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    img {
        width: 65px;
        height: 65px;
    }

    .start-card-heading {
        flex: 1;
        text-align: left;

        h2 {
            font-weight: 600;
            font-weight: 0.8rem;
            margin-top: 0px;
            margin-bottom: 10px;
        }

        p {
            font-weight: 0.8rem;
            margin: 0px;
            padding: 0px;
        }
    }

    .start-arrow-circle {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: $primary;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 24px;
            height: 24px;
            margin-bottom: 7px;
        }
    }
}