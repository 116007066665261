/* src/components/FormComponent.css */
.form-container {

   
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    text-align: left;
   
    
  
  }
  .questions{
    border-bottom: 1px solid gainsboro;
    
  }
  
  .form-question {
    margin-bottom: 1em;
  }
  
  .form-question p {
    font-size: 1em;
    
  }
  
  .button-group {
    display: flex;
    gap: 0.5em;
  }
  
  .button-group button {
    padding: 0.5em 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button-group button:hover {
    background-color: #0056b3;
  }
  
  select {
    padding: 0.5em;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .submit-button {
    padding: 0.75em 1.5em;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  